.stripes {
  background-image: repeating-linear-gradient(
    to right,
    transparent,
    transparent 218px,
    grey 2px,
    grey 219px
  );
  display: block;
  position: fixed;
  height: 105vh;
  width: 400vw;
  background-color: transparent;
  opacity: 0.14;
  z-index: 4;
  overflow: hidden;
  left: -300vw;
}

.logo {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
  animation: opanimate 8s linear infinite;
}

.logo:before {
  content: "";
  position: absolute;
  width: 220%;
  height: 175%;
  top: -32%;
  opacity: 0;
  left: -58%;
  background: url("../../images/ZMP-BG.svg");
  background-repeat: no-repeat;
  animation: animate 8s linear infinite;
  animation-play-state: running;
  mix-blend-mode: hard-light;
}

.logo.stopped:before {
  animation-play-state: paused;
  opacity: 0;
  background: transparent;
}

#bigLogo {
  width: 68px;
  height: 110px;
  opacity: 0;
  position: relative;
  margin: 5% 25% 5%;
  left: 1.5vw;
  top: 4vh;
  color: rgb(220, 220, 220);
  @media (min-width: $desktop-breakpoint) {
    width: 200px;
    left: -2vw;
  }
}

.letter {
  stroke-width: 1.273;
  fill: #fff;
}

div.logo > h2 {
  font-family: "LEIXO-DEMO";
  text-align: center;
  color: $orange;
  font-size: 1.2rem;
  margin-top: 0px;
  position: relative;
  margin: 30px auto;
  left: 0.5vw;
  top: 10vh;
  @media (min-width: $desktop-breakpoint) {
    font-size: 1.7rem;
  }
}

.subtitles {
  opacity: 0;
}

%container-home-styles {
  position: absolute;
  width: 100%;
  max-width: 4096px;
  margin: 0 auto;
  height: 100%;
  top: 0;
  opacity: 1;
  z-index: 5;
  left: 0;
  overflow: hidden;
}

.container-home {
  @extend %container-home-styles;
  opacity: 1;
}

.container-home:before {
  @extend %container-home-styles;
  content: "";
  animation: animate-bg 8s linear infinite;
  animation-play-state: running;
}

#particles-js {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: $light-black;
  background-image: url("");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

@keyframes animate-bg {
  0% {
    background: url("../../images/glitch.png");
    background-size: cover;
    background-position: 0 0;
    filter: hue-rotate(0deg);
  }
  0.4% {
    background-position: 5px 0;
    opacity: 0.5;
  }
  0.8% {
    background-position: -5px 0;
    opacity: 1;
  }
  1.2% {
    background-position: 15px 0;
  }
  1.6% {
    background-position: -5px 0;
  }
  2% {
    background-position: -25px 0;
  }
  2.4% {
    background-position: -50px 0;
  }
  2.8% {
    background-position: 0 -20px;
  }
  3.2% {
    background-position: -60px -20px;
  }
  3.24% {
    background-position: 0 0;
  }
  4% {
    background-position: 0 0;
    opacity: 0;
    filter: hue-rotate(360deg);
    background: none;
  }
}

@keyframes animate {
  0% {
    background-position: 0 0;
    filter: hue-rotate(0deg);
  }
  0.4% {
    background-position: 5px 0;
    opacity: 0.5;
  }
  0.8% {
    background-position: -5px 0;
    opacity: 1;
  }
  1.2% {
    background-position: 15px 0;
  }
  1.6% {
    background-position: -5px 0;
  }
  2% {
    background-position: -25px 0;
  }
  2.4% {
    background-position: -50px 0;
  }
  2.8% {
    background-position: 0 -20px;
  }
  3.2% {
    background-position: -60px -20px;
  }
  3.24% {
    background-position: 0 0;
  }
  4% {
    background-position: 0 0;
    opacity: 0;
    filter: hue-rotate(360deg);
  }
}

@keyframes opanimate {
  0% {
    opacity: 0;
  }
  4% {
    opacity: 1;
  }
}
