//COLORS
$orange: #fc7113; //sound
$light-black: rgba(35, 35, 35, 1);
$grey: rgb(143, 141, 141);
$green: #89ef40; //events
$violet: #aaaafbff; //ts
$aquamarine: #5ff2d4ff; //lights
$red: #f46c6eff; //3d
//SPACING
$sm: 0.5rem;
$md: 2rem;
$desktop-breakpoint: 55rem;
