.icons {
  @media (min-width: $desktop-breakpoint) {
    display: flex;
  }
  position: relative;
  float: right;
  display: none;
  flex-direction: row;
  justify-items: center;
  z-index: 6;
  bottom: 12px;
  right: 5px;
  transition: opacity 0.2s ease;
}

.icons-hidden {
  @media (max-width: 2500px) and (max-height: 728px) {
    opacity: 0;
  }
}

.icon {
  margin: 0 5px;
  height: 3rem;
  width: 3rem;
}

a {
  fill: $grey;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease;
}

a:hover {
  fill: $orange;
}
