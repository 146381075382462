.portfolio-sidebar {
  align-items: center;
  position: fixed;
  background: black;
  width: 100%;
  z-index: 6;
  display: none;
  opacity: 0;
  top: 0;
  left: -20rem;
  justify-content: space-between;
  flex-direction: column;
  @media (min-width: $desktop-breakpoint) {
    width: 280px;
    height: 100%;
  }
}

.close-btn {
  position: relative;
  top: 0;
  left: -30%;
  margin-top: 10%;
  cursor: pointer;
  width: 35px;
}

.close-btn > path {
  stroke-dashoffset: 0;
  stroke-dasharray: 30;
}

.close-btn:hover path.one {
  animation: draw-cross 0.5s linear forwards;
}

.close-btn:hover path.two {
  animation: draw-cross 0.5s linear forwards;
  animation-delay: 0.2s;
}

.menu-data {
  align-self: flex-start;
  position: relative;
  left: 17%;
  top: -45%;
  color: $grey;
  max-width: 80%;
}

@keyframes draw-cross {
  0% {
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dashoffset: -30;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
.portfolio-item {
  transition: color 0.2s;
  &:hover {
    color: #fff;
    cursor: pointer;
  }
}

// PORTFOLIO CONTENT
.portfolio-content {
  display: none;
  flex-direction: column;
  font-size: 18px;
  color: #fff;
  opacity: 0;
  padding: 25px;
  transition: opacity 0.5s ease;
}

.portfolio-overlay {
  background-color: rgba(35, 35, 35, 0); //same as $light-black;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  position: absolute;
  transition: background-color 0.2s ease;
  z-index: inherit;
  @media (min-width: $desktop-breakpoint) {
    margin-left: 248px;
  }
}

.portfolio-content-work-container {
  display: flex;
  width: 100%;
}
.portfolio-content-content {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}

.portfolio-img-row {
  display: flex;
  flex-wrap: wrap;
}

.portfolio-content-work {
  min-width: 350px;
  max-width: 35%;
  margin-right: 20px;
  padding-bottom: 20px;
  div {
    padding-bottom: 5px;
  }
  img {
    width: 100%;
  }
}
