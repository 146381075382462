.kontakt {
  left: 13vw;
  width: 100vw;
  display: flex;
  position: absolute;
  opacity: 1;
  top: 0;
  @media (max-width: 320px) and (max-height: 568px) {
    overflow-y: scroll;
  }
}

.kontakt__container {
  position: relative;
  top: 16vh;
  left: 0vw;
  display: flex;
  flex-direction: column;
  width: inherit;
  justify-content: space-around;
  @media (min-width: $desktop-breakpoint) {
    flex-direction: row;
    position: inherit;
    top: 0vh;
  }
}

.kontakt__text {
  opacity: 0;
  position: relative;
  flex-direction: column;
  left: -20%;
  font-size: 14px;
  max-width: 71vw;
  top: 0vh;
  @media (min-width: $desktop-breakpoint) {
    left: -15%;
    top: auto;
    align-self: center;
    max-width: 47vw;
  }
  @media (max-width: 970px) and (min-width: 415px) {
    max-width: 40vw;
  }
}

.kontakt__text > ul {
  color: $grey;
  @media (min-width: $desktop-breakpoint) {
    font-size: 21px;
    line-height: 2rem;
  }
}

.kontakt__text > ul > li {
  padding-bottom: 0.2rem;
  @media (min-width: $desktop-breakpoint) {
    padding-bottom: inherit;
  }
}

.map-container {
  display: none;
  opacity: 0;
  position: relative;
  height: 44vh;
  width: 75%;
  background: grey;
  right: -33%;
  z-index: 5;
  margin-top: 6%;
  @media (min-width: $desktop-breakpoint) {
    height: 100vh;
    width: 40%;
    margin-top: 0;
    top: inherit;
  }
}

#map {
  width: initial;
  height: inherit;
}
