.logoHomeIcon {
  height: 1.5rem;
  position: relative;
  @media (min-width: $desktop-breakpoint) {
    height: 3rem;
  }
}

.zmpSmall {
  position: relative;
  height: 1.5rem;
  align-self: center;
  left: -2rem;
  opacity: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  @media (min-width: $desktop-breakpoint) {
    height: 3rem;
  }
}

.homeIconDiv {
  display: flex;
  flex-direction: row;
}

.homeIconDiv:hover .zmpSmall {
  opacity: 1;
  left: 0;
}

.sidebar {
  display: none;
  z-index: 7;
  position: relative;

  flex-direction: column;
  justify-content: space-between;
  margin-left: 1.2rem;
  height: 95vh;
  top: 2rem;
  width: 10rem;
  @media (min-width: $desktop-breakpoint) {
    display: flex;
  }
}

.nav-option {
  position: absolute;
  font-weight: 700;
  opacity: 0;
  left: -2rem;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease;
}

.menu {
  display: none;
  @media (min-width: $desktop-breakpoint) {
    display: inherit;
  }
}

.menu-number:hover {
  .nav-option {
    opacity: 1;
    left: 2rem;
  }
}

.selected {
  color: $orange;
  text-decoration: line-through;
}

.portfolio-icon {
  fill: $orange;
  margin-right: $sm;
}

.portfolio-menu {
  display: none;
  @media (min-width: $desktop-breakpoint) {
    display: inherit;
  }
}
