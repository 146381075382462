.section {
  position: absolute;
  color: white;
  max-width: 70vw;
  flex-direction: column;
  left: 0;
  top: 10vh;
  display: none;
  opacity: 0;
  @media (min-width: $desktop-breakpoint) {
    top: 20vh;
  }
}

.section__historia {
  font-size: 14px;
  margin-bottom: 1.5 * $md;
  @media (min-width: $desktop-breakpoint) {
    font-size: 24px;
  }
  @media (max-height: 568px) {
    font-size: 13px;
  }
  @media (min-height: 667px) and (max-width: 414px) {
    font-size: 16px;
  }
}
