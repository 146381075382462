.serv-sidebar {
  align-items: center;
  position: absolute;
  background: white;
  height: 100vh;
  width: 100vw;
  z-index: 9;
  display: none;
  opacity: 0;
  top: 0;
  right: -285px;
  flex-direction: column;
  overflow: scroll;
  @media (min-width: $desktop-breakpoint) {
    width: 40rem;
  }
}

.service-template {
  width: inherit;
}

.tools {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 25px;
}

div.tools > div {
  text-align: center;
}

.ss__bg-picture > img {
  width: 100%;
  height: 100%;
  @media (min-width: $desktop-breakpoint) {
    // width: 619px;
    height: 460px;
  }
}

.ss__text-container {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  margin: $md 2 * $md;
}

.ss_text {
  color: black;
  margin: 25px 0 25px;
}

.ss__list > p,
.ss__list2 > p {
  font-weight: 800;
  color: black;
}

.ss__list2 > .tools {
  display: inline;
  @media (min-width: $desktop-breakpoint) {
    display: flex;
  }
}

.ss__icon {
  height: 70px;
  width: 45px;
}

.ss__icon_small {
  height: 55px;
  width: 55px;
}

.ss__icon_md {
  height: 55px;
  width: 120px;
}

.ss__icon_4d {
  height: 55px;
  width: $md * 2.5;
}

.ss__icon_small_adob {
  height: 100px;
  width: 220px;
}

h4 {
  font-size: 42px;
  margin: 1rem 0;
  font-weight: 800;
}

svg + p {
  text-align: center;
}

.close-btn-right {
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
  align-self: flex-end;
  width: 35px;
  path {
    stroke: $orange;
    stroke-width: 2px;
  }
}

.close-btn-right > path {
  stroke-dashoffset: 0;
  stroke-dasharray: 30;
}

.close-btn-right:hover path.one {
  animation: draw-cross 0.5s linear forwards;
}

.close-btn-right:hover path.two {
  animation: draw-cross 0.5s linear forwards;
  animation-delay: 0.2s;
}

.menu-data {
  align-self: flex-start;
  position: relative;
  left: 17%;
  top: -45%;
}

@keyframes draw-cross {
  0% {
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dashoffset: -30;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
