.section__ue {
  display: none;
  opacity: 0;
  position: absolute;
  flex-direction: column;
  overflow-y: auto;
  opacity: 1;
  left: 10vw;
  top: 70vh;
  width: 80vw;

  p {
    font-size: 16px;
    margin: auto 0;
    @media (min-width: 90rem) {
      font-size: 18px;
    }
  }
  @media (min-width: $desktop-breakpoint) {
    margin: 3rem 0 0 2rem;
  }
}

.strong_p {
  color: white;
  font-weight: 700;
}

.section__ue__footer {
  display: flex;
  flex-direction: row;
  background-color: white;
  width: 100%;
  height: auto;
  max-height: 7rem;
  margin: 2rem 0;
  align-items: center;
  @media (min-width: 880px) and (max-width: 1181px) {
    margin-bottom: 6rem;
  }
  .ue_flags {
    width: 25%;
    min-height: auto;
    img {
      height: auto;
      max-height: 6rem;
    }
  }
}
